import React, { useEffect } from "react";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import styles from "../styles/container/Container.module.scss";
import mainLogo from "../icons/logo.png";

const Header = () => {
    const { isConnected } = useAccount();
    const { switchNetwork } = useSwitchNetwork()
    const { chain } = useNetwork();
    useEffect(() => {
        const switchChain = async () => {
            try {
                switchNetwork?.(56)
            } catch (e) {
                console.error(e)
            }
        }
        if (isConnected === true) {
            if (chain.id !== 56)
                switchChain();
        }
    }, [isConnected, chain, switchNetwork])

    return (
        <div>
            <div className={styles.HeaderContainer}>
                <div className={styles.HeaderContainer}>
                    <section className={styles.BalanceSection}>
                        <img src={mainLogo} alt="logo" />
                        
                    </section>
                    <section className={styles.ButtonContainer}>
                    </section>
                </div>
            </div>
            <div className={styles.HeaderLine}></div>
        </div>
    );
};

export default Header;