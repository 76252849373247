import React from "react";
import styles from "../styles/container/Container.module.scss";

const Footer = () => {
    return (
        <div className={styles.footer}>
            <section className={styles.footerContainer}>
                <p>Kindly be aware that upon Token Generation Event (TGE) or Listing day, 50% of your acquired BOTIFI tokens will be transferred to your wallet, while the remaining 50% will follow a structured vesting schedule.</p>
                {/* <a className={styles.dashboardText} href="https://t.me" target="_blank" rel="noreferrer">
                    <p>Telegram</p>
                </a>
                <a className={styles.dashboardText} href="https://twitter.com" target="_blank" rel="noreferrer">
                    <p>Twitter</p>
                </a> */}
            </section>
        </div>
    );
};

export default Footer;
