/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import '../App.css'
import TokenAbi from '../config/TokenAbi.json'
import PresaleAbi from '../config/PresaleAbi.json'
import "../styles/PresaleContainer.css";
import Input from "../components/Input.tsx";
import Footer from "../container/Footer.jsx";
import { readContract, writeContract } from '@wagmi/core'
import ClipLoader from "react-spinners/ClipLoader";
import { waitForTransaction } from '@wagmi/core'

import bnb from '../icons/bnb.svg';
import eth from '../icons/eth.png';
import usdt from '../icons/USDT.svg';
import chance from '../icons/CHANCE.png'
import Web3 from "web3";
import { useWeb3Modal } from "@web3modal/react";
import styles from "../styles/container/Container.module.scss";

const App = () => {
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const [tokenAmount, setAmount] = useState(0);
  const [allowanceAmount, setAllowanceAmount] = useState(0);
  const [raisedAmount, setRaisedAmount] = useState(0);
  let [loading, setLoading] = useState(false);
  let [confirming, setConfirming] = useState(false);
  const PresaleAddress = "0x0804de576fbd696caee2b71aa62c2a75839e612e";
  const ethAddress = "0x2170Ed0880ac9A755fd29B2688956BD959F933F8";
  // "0xc73dF9AF68F7d749ff446A7640A2d93e20BFB1d4";
  const usdtAddress = "0x55d398326f99059fF775485246999027B3197955";
  // "0x78867bbeef44f2326bf8ddd1941a4439382ef2a7";

  const [selectedToken, setSelectToken] = useState(0);
  const [selectedTokenName, setSelectedTokenName] = useState("BNB");
  const [outTokenAmount, setOutTokenAmount] = useState(0);
  let [accountBalance, setAccountBalance] = useState(0);
  const BSC_PROVIDER_URL = "https://bsc.publicnode.com";
  let BSCweb3 = new Web3(new Web3.providers.HttpProvider(BSC_PROVIDER_URL));
  const [progress, setProgress] = useState('0%');
  const totalAmount = 2381575;
  const { open } = useWeb3Modal();
  const onConnect = async () => {
    await open();
  };
  const { isLoading, pendingChainId, switchNetwork } = useSwitchNetwork()

  useEffect(() => {
    const switchChain = async () => {
      try {
        switchNetwork?.(56)
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true) {
      if (chain.id !== 56)
        switchChain();
    }
  }, [isConnected, chain?.id, switchNetwork])


  useEffect(() => {
    if (loading === true) {
      setTimeout(function () {
        setLoading(false)
      }, 3000)
    }
  }, [loading])

  const onTokenAllowance = async () => {
    try {
      setConfirming(true);
      let approve;
      if (selectedToken === 1) {
        approve = await writeContract({ address: ethAddress, abi: TokenAbi, functionName: 'approve', args: [PresaleAddress, ethers.utils.parseUnits(tokenAmount, "ether")] })
      } else if (selectedToken === 2) {
        approve = await writeContract({ address: usdtAddress, abi: TokenAbi, functionName: 'approve', args: [PresaleAddress, ethers.utils.parseUnits(tokenAmount, "ether")] })
      }
      const approveData = await waitForTransaction({
        hash: approve.hash
      })
      console.log('approveData', approveData)
      setTimeout(function () {
        setConfirming(false);
      }, 3000)
      let allowance;
      if (selectedToken === 1) {
        allowance = await readContract({ address: ethAddress, abi: TokenAbi, functionName: 'allowance', args: [address, PresaleAddress] });
      } else if (selectedToken === 2) {
        allowance = await readContract({ address: usdtAddress, abi: TokenAbi, functionName: 'allowance', args: [address, PresaleAddress] });
      }
      setAllowanceAmount(ethers.utils.formatEther(allowance));
    } catch (err) {
      setConfirming(false);
    }
  };

  const onTokenDeposit = async () => {
    try {
      setConfirming(true);
      let deposit;
      if (selectedToken === 0) {
        deposit = await writeContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'BuyWithBNB', value: BSCweb3.utils.toWei(String(tokenAmount), 'ether') })
      } else if (selectedToken === 1) {
        deposit = await writeContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'BuyWithETH', args: [BSCweb3.utils.toWei(String(tokenAmount), 'ether')] })
      } else {
        deposit = await writeContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'BuyWithUSDT', args: [BSCweb3.utils.toWei(String(tokenAmount), 'ether')] })
      }
      const depositData = await waitForTransaction({
        hash: deposit.hash
      })
      console.log('depositData', depositData)
      setTimeout(function () {
        setConfirming(false);
      }, 3000)
      const Amount = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'USDraised' });
      setRaisedAmount(Amount);
      const percent = (Number(raisedAmount) / (10 ** 18)) * 100 / totalAmount;
      const prog = percent.toFixed(3) + '%';
      setProgress(prog);
    } catch (err) {
      setConfirming(false);
    }
  };

  const setMaxAmount = async () => {
    if (selectedToken === 0 && accountBalance > 0)
      accountBalance = accountBalance - 0.01;
    setAmount(accountBalance);
  };



  const BNBSelect = async () => {
    try {
      setSelectToken(0)
    } catch (err) {
    }
  }

  const ETHSelect = async () => {
    try {
      setSelectToken(1)
    } catch (err) {
    }
  }

  const USDTSelect = async () => {
    try {
      setSelectToken(2)
    } catch (err) {
    }
  }

  useEffect(() => {
    const FetchData = async () => {
      try {
        const Amount = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'USDraised' });
        setRaisedAmount(Amount);

        if (address) {
          if (selectedToken === 0) {
            setSelectedTokenName("BNB");
            // eslint-disable-next-line react-hooks/exhaustive-deps
            accountBalance = await BSCweb3.eth.getBalance(address);
            accountBalance = BSCweb3.utils.fromWei(accountBalance, 'ether');
            setAccountBalance(accountBalance)
          } else if (selectedToken === 1) {
            setSelectedTokenName("ETH");
            const balance = await readContract({ address: ethAddress, abi: TokenAbi, functionName: 'balanceOf', args: [address] });
            setAccountBalance(ethers.utils.formatEther(balance));
          } else {
            setSelectedTokenName("USDT");
            const balance = await readContract({ address: usdtAddress, abi: TokenAbi, functionName: 'balanceOf', args: [address] });
            setAccountBalance(ethers.utils.formatEther(balance));
          }
        }

      } catch (e) {
        console.error(e)
      }
    }
    FetchData();
  }, [selectedToken, address])

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const percent = (Number(raisedAmount) / (10 ** 18)) * 100 / totalAmount;
        const prog = percent.toFixed(3) + '%';
        setProgress(prog);
      } catch (e) {
        console.error(e)
      }
    }
    if (Number(raisedAmount) > 0) {
      fetchProgress();
    }
  }, [raisedAmount])

  useEffect(() => {
    const FetchData = async () => {
      try {
        let balance;
        if (selectedToken === 0) {
          balance = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'getvalueperBNB', args: [BSCweb3.utils.toWei(String(tokenAmount), 'ether')] });
        } else if (selectedToken === 1) {
          balance = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'getvalueperETH', args: [BSCweb3.utils.toWei(String(tokenAmount), 'ether')] });
        } else {
          balance = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'getvalueperUSDT', args: [BSCweb3.utils.toWei(String(tokenAmount), 'ether')] });
        }
        setOutTokenAmount(BSCweb3.utils.fromWei(String(balance), 'ether'))
      } catch (e) {
        console.error(e)
      }
    }
    if (tokenAmount > 0)
      FetchData();
  }, [BSCweb3.utils, selectedToken, tokenAmount])

  useEffect(() => {
    const useTokenCheck = async () => {
      try {
        let allowance;
        if (selectedToken === 1) {
          allowance = await readContract({ address: ethAddress, abi: TokenAbi, functionName: 'allowance', args: [address, PresaleAddress] });
        } else if (selectedToken === 2) {
          allowance = await readContract({ address: usdtAddress, abi: TokenAbi, functionName: 'allowance', args: [address, PresaleAddress] });
        }
        setAllowanceAmount(ethers.utils.formatEther(allowance));
      } catch (e) {
        console.error(e)
      }
    }
    if (tokenAmount > 0) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useTokenCheck();
    }
  }, [address, selectedToken, tokenAmount])

  return (
    <main>
      <div className="GlobalContainer">
        {
          <div className="MainDashboard">
            <section className="ContactBox">
              <>
                <section className="ContractContainer">
                  <section className="DepositBoxHeader">
                    <p className="ContractContentTextTitle">PRESALE <span className="gradient-text">LIVE</span> STAGE 1</p>
                  </section>
                  <section className='HeaderContent'>
                    <p>Raised</p>
                    <p>Total Amount</p>
                  </section>
                  <section className='HeaderContent2'>
                    <p>$ {Number((Number(raisedAmount) / (10 ** 18)).toFixed(0)).toLocaleString()}</p>
                    <p>$2,381,575</p>
                  </section>
                  <section className='HeaderContent3'>
                    <section className='HeaderContent4' style={{ width: progress }}></section>
                  </section>
                  <section className='HeaderContent5'>
                    <span>UNTIL SOLD OUT</span>
                  </section>
                  <section className='HeaderContent6'>
                    <section className='stageBox starting'>
                      <p>Stage 1</p>
                      <span>$0.09 = 1 CHANCE</span>
                    </section>
                    <section className='stageBox unstarted'>
                      <p>Stage 2</p>
                      <span>$0.11 = 1 CHANCE</span>
                    </section>
                    <section className='stageBox unstarted'>
                      <p>Stage 3</p>
                      <span>$0.14 = 1 CHANCE</span>
                    </section>
                    <section className='stageBox unstarted'>
                      <p>Stage 4</p>
                      <span>$0.17 = 1 CHANCE</span>
                    </section>
                  </section>
                  <p class="one_how">1 CHANCE = $0.09</p>
                  <section className='tokensButton'>
                    {selectedToken === 0 ?
                      <button className="bnbButton tokenButtons selected" onClick={BNBSelect}>
                        <img className='tokenImage' src={bnb} />&nbsp;BNB
                      </button>
                      :
                      <button className="bnbButton tokenButtons" onClick={BNBSelect}>
                        <img className='tokenImage' src={bnb} />&nbsp;BNB
                      </button>
                    }
                    {selectedToken === 1 ?
                      <button className="bnbButton tokenButtons selected" onClick={ETHSelect}>
                        <img className='tokenImage' src={eth} />&nbsp;ETH
                      </button>
                      :
                      <button className="bnbButton tokenButtons" onClick={ETHSelect}>
                        <img className='tokenImage' src={eth} />&nbsp;ETH
                      </button>
                    }
                    {selectedToken === 2 ?
                      <button className="bnbButton tokenButtons selected" onClick={USDTSelect}>
                        <img className='tokenImage' src={usdt} />&nbsp;USDT
                      </button>
                      :
                      <button className="bnbButton tokenButtons" onClick={USDTSelect}>
                        <img className='tokenImage' src={usdt} />&nbsp;USDT
                      </button>
                    }
                  </section>
                  <section className='BalancePart'>
                    {selectedTokenName} Balance : {Number(accountBalance).toFixed(3)}
                  </section>

                  <>
                    <section className='InputBox'>
                      <section className='InputSection'>
                        <div className='LpBalance'>
                          <p className='Text1'>You Pay : </p>
                          <p onClick={setMaxAmount} className="MaxButton">Max</p>
                        </div>
                        <section className='inputPanel'>
                          <section className='inputPanelHeader'>
                            <Input
                              placeholder="Enter amount"
                              label=""
                              type="number"
                              changeValue={setAmount}
                              value={tokenAmount}
                            />
                            {selectedToken === 0 ?
                              <img className='tokenImage' src={bnb} />
                              :
                              selectedToken === 1 ?
                                <img className='tokenImage' src={eth} />
                                :
                                <img className='tokenImage' src={usdt} />}

                          </section>
                        </section>
                      </section>
                      <section className='InputSection'>
                        <div className='LpBalance'>
                          <p className='Text1'>Amount Of Tokens </p>
                        </div>
                        <section className='inputPanel'>
                          <section className='inputPanelHeader'>
                            <input
                              placeholder="0"
                              label=""
                              type="number"
                              value={outTokenAmount}
                            />
                            <img className='tokenImage' src={chance} />
                          </section>
                        </section>
                      </section>
                    </section>
                    {Number(tokenAmount) > Number(allowanceAmount) && selectedToken !== 0 ?
                      <section className="LockBox">
                        {confirming === false ?
                          <>
                            <button disabled={false} onClick={onTokenAllowance} className="PresaleButton">Allow Tokens First</button>
                          </>
                          :
                          <>
                            <p className='Text1'>Approving</p>
                            <ClipLoader
                              color={'#36d7b7'}
                              loading={confirming}
                              size={20}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </>
                        }
                      </section>
                      :
                      <>
                        {
                          <section className="LockBox">
                            {confirming === false ?
                              <>
                                <button disabled={false} onClick={onTokenDeposit} className="PresaleButton">Deposit Token Now!</button>
                              </>
                              :
                              <>
                                <p className='Text1'>Depositing...</p>
                                <ClipLoader
                                  color={'#36d7b7'}
                                  loading={confirming}
                                  size={20}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </>
                            }
                            {!isConnected ?
                              <>
                                <button className="PresaleButton" type="submit" onClick={() => {
                                  onConnect();
                                }}>Connect Wallet</button>
                              </>
                              :
                              <section className={styles.ConnectWalletSection}>
                                {chain?.id === 56 ?
                                  <button
                                    className="PresaleButton" type="submit"
                                    onClick={() => onConnect()}
                                  >
                                    {address.slice(0, 5) + '...' + address.slice(-5)}
                                  </button>
                                  :
                                  <button
                                    className="PresaleButton" type="submit"
                                    onClick={() => switchNetwork?.(56)}
                                  >
                                    {'Switch To BSC'}
                                    {isLoading && pendingChainId === 56 && ' (switching)'}
                                  </button>
                                }
                              </section>
                            }
                          </section>
                        }
                      </>
                    }
                  </>
                  <div className="bnbPoolContentLine"></div>
                </section>
              </>
            </section>
          </div>
        }
      </div>
      <Footer />
    </main >
  )
}

export default App
