import React from "react";
import styles from "./App.module.scss";
import Header from "./container/Header";
import PresaleContainer from "./container/PresaleContainer";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params';
import { WagmiConfig, createConfig, configureChains } from 'wagmi'
import { bsc } from 'wagmi/chains'

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from "@web3modal/react";

const projectId = 'a2f9a6e6e8209d146104e59ac4917286'

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc],
  [w3mProvider({ projectId })],
)


const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'wagmi',
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: 'a2f9a6e6e8209d146104e59ac4917286',
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
})

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains);


const App = () => {
  return (
    <Router>
      <QueryParamProvider>
        <div className={styles.App}>
          <WagmiConfig config={config}>
            <Header />
            <Switch>
              <Route exact path="/">
                <PresaleContainer />
              </Route>
            </Switch>
          </WagmiConfig>
          <Web3Modal
            projectId="a2f9a6e6e8209d146104e59ac4917286"
            ethereumClient={ethereumClient}
          />
        </div>
      </QueryParamProvider>
    </Router>
  );
};

export default App;
